:root {
  --s-textcolor: #023E84;
  --s-linkcolor: #F6A124;
  --s-linkcolor-hover: #023E84;
  --s-btn-bg: #F6A124;
  --s-btn-color: #023E84;
  --s-btn-deco-color: #FFFFFF;
  --s-btn-bg-hover: #023E84;
  --s-btn-color-hover: #F6A124;
  --s-btn-deco-color-hover: #FFFFFF;
  --s-folding-number-color: #e6ecf3;
}
:root {
  --deskWidth: 1160px;
  --deskSpace: 30px;
  --deskMaxWidth: calc(100vw - (2 * var(--deskSpace)));
}
:root {
  --spacePart: 25px;
}
@media (max-width: 1023px) {
  :root {
    --spacePart: 25px;
  }
}
@media (max-width: 767px) {
  :root {
    --spacePart: 25px;
  }
}
:root {
  --spaceUnit: 25px;
}
@media (max-width: 1023px) {
  :root {
    --spaceUnit: 25px;
  }
}
@media (max-width: 767px) {
  :root {
    --spaceUnit: 25px;
  }
}
:root {
  --spaceTotal: 50px;
}
@media (max-width: 1023px) {
  :root {
    --spaceTotal: 50px;
  }
}
@media (max-width: 767px) {
  :root {
    --spaceTotal: 50px;
  }
}
:root {
  --js-breakpoint: breakpointLarge;
}
@media (max-width: 1023px) {
  :root {
    --js-breakpoint: breakpointMedium;
  }
}
@media (max-width: 767px) {
  :root {
    --js-breakpoint: breakpointSmall;
  }
}
.title1 {
  font-size: 53px;
  line-height: 1.16981132;
  color: var(--s-textcolor);
  font-family: 'Noto Serif', serif;
  position: relative;
  padding-bottom: 20px;
  hyphens: auto;
}
.title1:after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 30px;
  height: 9px;
  background-color: #F6A124;
}
@media (max-width: 1023px) {
  .title1 {
    font-size: 28px;
    line-height: 1.14285714;
  }
}
.title2 {
  font-size: 35px;
  line-height: 1.17142857;
  color: var(--s-textcolor);
  font-family: 'Noto Serif', serif;
  position: relative;
  padding-bottom: 20px;
}
.area--one .title2:after,
.area--intro .title2:after,
.section--teaserSlider .title2:after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 30px;
  height: 9px;
  background-color: #F6A124;
}
@media (max-width: 1023px) {
  .title2 {
    font-size: 28px;
    line-height: 1.14285714;
  }
}
.title3 {
  font-size: 26px;
  line-height: 1.19230769;
  color: var(--s-textcolor);
}
@media (max-width: 1023px) {
  .title3 {
    font-size: 21px;
    line-height: 1.19047619;
  }
}
.lead {
  font-size: 24px;
  line-height: 1.26086957;
  color: var(--s-textcolor);
  font-weight: 300;
}
@media (max-width: 1023px) {
  .lead {
    font-size: 22px;
    line-height: 1.18181818;
  }
}
.copy {
  font-size: 21px;
  line-height: 1.19047619;
  color: var(--s-textcolor);
  font-weight: 300;
}
.smallText {
  font-size: 17px;
  line-height: 1.17647059;
}
.quote {
  font-family: 'Noto Serif', serif;
  font-size: 28px;
  line-height: 1.14285714;
  color: var(--s-textcolor);
  font-style: italic;
}
@media (max-width: 1023px) {
  .quote {
    font-size: 26px;
    line-height: 1.19230769;
  }
}
.footnote {
  font-size: 219px;
  line-height: 0.79908676;
  letter-spacing: 0.02em;
  color: var(--s-folding-number-color);
  font-family: 'Noto Serif', serif;
  transition: all 0.4s;
}
@media (max-width: 1023px) {
  .footnote {
    font-size: 100px;
    line-height: 0.8;
  }
}
.moodSlogan {
  font-size: 53px;
  line-height: 1.16981132;
  color: #FFFFFF;
  text-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
  text-align: right;
  font-weight: bold;
}
@media (max-width: 1023px) {
  .moodSlogan {
    font-size: 22px;
    line-height: 1.08333333;
  }
}
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.button {
  display: inline-block;
  background-color: var(--s-btn-bg);
  font-size: 26px;
  line-height: 1;
  font-weight: bold;
  cursor: pointer;
  color: var(--s-btn-color);
  min-width: 112px;
  padding: 9px 26px 26px 9px;
  appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-decoration: none;
  position: relative;
  transition: all 0.4s;
}
.button:before,
.button:after {
  content: '';
  position: absolute;
  left: 12px;
  bottom: 12px;
  width: 30px;
  height: 9px;
  background-color: var(--s-btn-deco-color);
  transition: all 0.4s;
}
.button:hover,
.button:focus {
  background-color: var(--s-btn-bg-hover);
  color: var(--s-btn-color-hover);
}
.button:hover:after,
.button:focus:after {
  background-color: var(--s-btn-deco-color-hover);
}
@media (max-width: 1023px) {
  .button {
    padding: 5px 30px 30px 12px;
    font-size: 21px;
    line-height: 1.19047619;
  }
}
/* gantari-300 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Gantari';
  font-style: normal;
  font-weight: 300;
  src: url('/extras/fonts/gantari-v1-latin-300.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* gantari-regular - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Gantari';
  font-style: normal;
  font-weight: 400;
  src: url('/extras/fonts/gantari-v1-latin-regular.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* gantari-700 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Gantari';
  font-style: normal;
  font-weight: 700;
  src: url('/extras/fonts/gantari-v1-latin-700.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* noto-serif-italic - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Noto Serif';
  font-style: italic;
  font-weight: 400;
  src: url('/extras/fonts/noto-serif-v23-latin-italic.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* noto-serif-700 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Noto Serif';
  font-style: normal;
  font-weight: 700;
  src: url('/extras/fonts/noto-serif-v22-latin-700.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
* {
  margin: 0;
  padding: 0;
  outline: 0;
  border: none;
  background: none;
  font-size: 100%;
}
*::selection {
  background: #023E84;
  color: #fff;
}
.hidden,
.cb-invisible {
  display: none !important;
}
li {
  list-style-position: inside;
}
img,
audio,
video,
iframe {
  float: left;
  width: 100%;
}
strong {
  font-weight: bold;
}
sup,
sub {
  position: relative;
  vertical-align: baseline;
  top: -0.8em;
  font-size: 70%;
}
sub {
  top: 0.4em;
}
address {
  font-style: normal;
}
input,
textarea {
  border-radius: 0;
  font-size: 21px;
  font-family: 'Gantari', sans-serif;
  line-height: 1.19047619;
}
.unit caption {
  display: none;
}
.flag {
  background: #023E84;
  color: #fff;
}
img.cb-loading {
  background-size: 30px 30px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-loader.svg);
}
.cbdModule {
  float: left;
  width: 100%;
}
#view .cbdModule.is-empty {
  display: none;
}
.cbDynamicContent__element {
  float: left;
  width: 100%;
}
.area {
  float: left;
  width: 100%;
}
#view .area:empty {
  display: none;
}
.unit {
  float: left;
  width: 100%;
  position: relative;
}
.area .unit {
  margin-top: var(--spaceUnit);
  margin-bottom: var(--spaceUnit);
}
.unit__background {
  float: left;
  width: 100%;
}
.unit__content {
  float: left;
  width: 100%;
}
.unit__head,
.unit__body,
.unit__foot {
  float: left;
  width: 100%;
}
.unit--fold div.less,
.unit--fold div.more {
  float: left;
  width: 100%;
}
.unit--fold .ctrl {
  float: left;
  width: 100%;
}
.part {
  float: left;
  position: relative;
}
.unit .part {
  width: 100%;
  margin-top: var(--spacePart);
  margin-bottom: var(--spacePart);
}
.part__content {
  float: left;
  width: 100%;
}
.pict a {
  float: left;
  width: 100%;
}
.pict img {
  width: 100%;
}
.pict img:not(.svg) {
  width: auto;
  max-width: 100% !important;
}
#root#root#root img.zoom {
  display: none !important;
}
#edit .part video {
  pointer-events: none;
}
.cb-image-figure {
  float: left;
  width: 100%;
}
.cb-image-container {
  float: left;
  width: 100%;
  position: relative;
}
.cb-image-caption {
  float: left;
  width: 100%;
  margin-top: 6px;
}
#disp.zoom .cb-image-figure {
  height: 100%;
}
#disp.zoom .cb-image-container {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#disp.zoom .cb-image-caption {
  display: none;
}
.cb-album .cb-image-figure {
  opacity: 1 !important;
  display: block !important;
}
.cb-album .cb-image-container {
  position: static;
}
.cb-album .cb-image-caption {
  display: none;
}
div.load {
  background: none !important;
}
div.load a.load {
  display: inline-block;
  padding-left: 26px;
  transition: all 0.4s;
  position: relative;
}
div.load a.load:before {
  content: '';
  position: absolute;
  left: 0;
  top: 7px;
  width: 16px;
  height: 16px;
  mask-size: 100% 100%;
  mask-position: 50% 50%;
  mask-repeat: no-repeat;
  mask-image: url(/images/icon-download.svg);
  background-color: var(--s-linkcolor);
  transition: all 0.4s;
}
div.load a.load:hover:before,
div.load a.load:focus:before {
  background-color: var(--s-linkcolor-hover);
}
.show a {
  float: left;
  width: 100%;
  display: block;
}
#edit .line {
  padding-top: 5px;
  padding-bottom: 5px;
}
.line hr {
  float: left;
  width: 100%;
  border-bottom: 1px solid var(--s-textcolor);
}
.line hr.bold {
  border-bottom-width: 2px;
}
.line hr.dash {
  border-bottom-style: dashed;
}
.line hr.spot {
  border-bottom-style: dotted;
}
#edit div.code {
  position: relative;
  min-height: 30px;
}
#edit div.code:after {
  content: 'Code';
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 2;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  background: #aaa;
  opacity: 0.6;
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 11px;
  letter-spacing: 0.2em;
  padding: 0 20px;
}
.cb-code-executable {
  float: left;
  width: 100%;
}
.part.cb-youtube iframe,
.part.cb-vimeo iframe {
  height: 100%;
  background-color: #fff;
}
.part.cb-share {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.part.cb-share .cb-service {
  float: left;
  margin-left: 16px;
}
.part.cb-share .cb-service:first-child {
  margin-left: 0;
}
.part.cb-share .cb-service * {
  float: left;
}
.part.cb-share .cb-service a {
  float: left;
  width: 50px;
  height: 50px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent !important;
  box-sizing: border-box;
  background-color: #fff;
  border: 2px solid #000;
  border-radius: 10000px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}
.part.cb-share .cb-service a:hover {
  filter: invert(1);
}
.part.cb-share .cb-service.cb-service-facebook-share a {
  background-size: auto 24px;
  background-image: url(/images/cb-default/socialShare-facebook-black.svg);
}
.part.cb-share .cb-service.cb-service-twitter a {
  background-size: auto 18px;
  background-image: url(/images/cb-default/socialShare-twitter-black.svg);
}
.text-section {
  float: left;
  width: 100%;
}
.list {
  float: left;
  width: 100%;
}
.list--bullet li {
  list-style: none;
  padding-left: 24px;
  position: relative;
}
.list--bullet li p {
  font-size: 21px;
  line-height: 1.19047619;
  color: var(--s-textcolor);
  font-weight: 300;
}
.list--bullet li:before {
  content: '•';
  position: absolute;
  left: 0;
  top: 0;
  font-size: 21px;
  line-height: 1.19047619;
  color: var(--s-textcolor);
  font-weight: 300;
}
.list--numbered {
  counter-reset: numberedList;
}
.list--numbered li {
  list-style: none;
  padding-left: 24px;
  position: relative;
}
.list--numbered li p {
  font-size: 21px;
  line-height: 1.19047619;
  color: var(--s-textcolor);
  font-weight: 300;
}
.list--numbered li:before {
  content: counter(numberedList) ".";
  counter-increment: numberedList;
  position: absolute;
  left: 0;
  top: 0;
  font-size: 21px;
  line-height: 1.19047619;
  color: var(--s-textcolor);
  font-weight: 300;
}
#expo {
  float: left;
  width: 100%;
  height: 100%;
  position: relative;
}
#slides {
  float: left;
  width: 100%;
  height: 100% !important;
  max-width: 100% !important;
  max-height: 100% !important;
  position: relative;
  overflow: hidden;
}
#slides .slide {
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100% !important;
}
#slides a {
  display: block;
  width: 100%;
  height: 100%;
}
#slides a.null {
  cursor: default;
}
.cb-slides .cb-image-figure {
  height: 100%;
}
.cb-slides .cb-image-container {
  height: 100% !important;
}
#slides img {
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: cover;
  object-position: center;
}
.onload-animation #slides img {
  animation-name: zoomIn;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0.3, 0, 0.7, 1);
}
@keyframes zoomIn {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.2);
  }
}
#expo div.link {
  float: left;
  width: 100%;
  height: 0;
}
@media (max-width: 1023px) {
  #expo div.link {
    display: none;
  }
}
#expo a.link {
  position: absolute;
  bottom: 35px;
  z-index: 2;
  width: 25px;
  height: 30px;
  transform: translateY(-50%);
  background: #FFFFFF;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent !important;
  mask-size: 100% 100%;
  mask-position: 50% 50%;
  mask-repeat: no-repeat;
}
#expo a.link.prev {
  right: 100px;
  mask-image: url(/images/arrow-left.svg);
}
@media (max-width: 1023px) {
  #expo a.link.prev {
    right: 70;
  }
}
#expo a.link.next {
  right: 45px;
  mask-image: url(/images/arrow-right.svg);
}
table.link,
table.link tbody {
  float: left;
  display: none;
  width: 100%;
  height: 0;
}
.cb-index-all {
  display: block;
  position: absolute;
  left: 0;
  bottom: 20px;
  z-index: 2;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.cb-index-all td {
  float: left;
  display: block;
  margin: 0 10px;
}
.cb-index-all td.init {
  margin-left: 0;
}
.cb-index-all td.exit {
  margin-right: 0;
}
.cb-index-all a {
  display: block;
  width: 16px;
  height: 16px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent !important;
  background: #fff;
  border-radius: 16px;
  transition: all 0.4s;
}
.cb-index-all td.this a {
  background: #023E84;
}
.cb-index-some {
  display: block;
  position: absolute;
  right: 20px;
  bottom: 20px;
  z-index: 2;
}
.cb-index-some td {
  float: left;
  display: block;
  color: #fff;
}
.cb-index-some td:first-child:after {
  display: inline-block;
  content: '/';
  margin: 0 5px;
}
.cb-index-some a {
  color: #fff;
}
.cb-index-some a:hover,
.cb-index-some a:focus {
  color: #fff;
}
#over {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5000;
  width: 100%;
  height: 100% !important;
  background-color: rgba(0, 0, 0, 0.8);
}
#disp {
  position: absolute;
  z-index: 5001;
  top: 0 !important;
  left: 50% !important;
  transform: translateX(-50%);
  width: 1000px !important;
  max-width: 90%;
  min-height: 100vh;
  display: flex !important;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  margin: 0 !important;
  box-sizing: border-box;
  padding: 80px 0 !important;
  font-size: 14px;
  font-family: 'Gantari', sans-serif;
  line-height: 1.42857143;
  color: var(--s-textcolor);
}
@media (max-width: 767px) {
  #disp {
    padding: 20px 0 !important;
  }
}
.disp-wrapper {
  float: left;
  width: 100%;
  background-color: #fff;
  box-sizing: border-box;
  padding: 40px;
}
@media (max-width: 767px) {
  .disp-wrapper {
    padding: 15px;
  }
}
#disp form {
  float: left;
}
.disp-wrapper > form {
  width: 100%;
}
@media (max-width: 767px) {
  #disp form {
    width: 100%;
  }
}
#disp .fail {
  color: #ae2121;
}
#disp .part {
  margin-top: 4px;
  margin-bottom: 4px;
}
#disp a.mail {
  float: left;
  min-height: 26px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
}
#disp .head,
#disp .body,
#disp .foot {
  float: left;
  width: 100%;
  position: relative;
}
#disp .body {
  margin: 20px 0;
}
#disp .foot form + form {
  float: right;
}
#disp h2 {
  font-size: 14px;
  line-height: 1.71428571;
  font-weight: normal;
  text-transform: uppercase;
  color: #023E84;
  width: 100%;
  box-sizing: border-box;
  padding-right: 30px;
}
#disp h3 {
  color: #023E84;
  font-size: 16px;
  font-weight: bold;
  padding: 20px 0;
  width: 100%;
}
#disp .head .ctrl {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
}
#disp .head .ctrl > div {
  float: left;
}
#disp .head .ctrl a {
  float: left;
  display: block;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent !important;
  width: 16px;
  height: 24px;
  background-size: 16px 16px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-cross-black.svg);
}
#disp .foot input,
#disp .foot a {
  float: left;
  display: inline-block;
  background-color: var(--s-btn-bg);
  font-size: 26px;
  line-height: 1;
  font-weight: bold;
  cursor: pointer;
  color: var(--s-btn-color);
  min-width: 112px;
  padding: 9px 26px 26px 9px;
  appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-decoration: none;
  position: relative;
  transition: all 0.4s;
  line-height: 1.42857143;
}
#disp .foot input:before,
#disp .foot a:before,
#disp .foot input:after,
#disp .foot a:after {
  content: '';
  position: absolute;
  left: 12px;
  bottom: 12px;
  width: 30px;
  height: 9px;
  background-color: var(--s-btn-deco-color);
  transition: all 0.4s;
}
#disp .foot input:hover,
#disp .foot a:hover,
#disp .foot input:focus,
#disp .foot a:focus {
  background-color: var(--s-btn-bg-hover);
  color: var(--s-btn-color-hover);
}
#disp .foot input:hover:after,
#disp .foot a:hover:after,
#disp .foot input:focus:after,
#disp .foot a:focus:after {
  background-color: var(--s-btn-deco-color-hover);
}
@media (max-width: 1023px) {
  #disp .foot input,
  #disp .foot a {
    padding: 5px 30px 30px 12px;
    font-size: 21px;
    line-height: 1.19047619;
  }
}
#disp .foot input.next,
#disp .foot a.next {
  float: right;
}
#disp.mail .foot input {
  float: right;
}
@media (max-width: 767px) {
  #disp .foot input,
  #disp .foot a {
    width: 100%;
    margin: 5px 0;
  }
  #disp .foot input:first-child,
  #disp .foot a:first-child {
    margin-top: 0;
  }
  #disp .foot input:last-child,
  #disp .foot a:last-child {
    margin-bottom: 0;
  }
}
#disp fieldset {
  float: left;
  width: 100%;
  position: relative;
  margin: 4px 0;
}
#disp fieldset.tiny {
  width: 48%;
}
#disp .head fieldset,
#disp .foot fieldset {
  margin: 0;
}
@media (max-width: 767px) {
  #disp fieldset.tiny {
    width: 100%;
  }
}
#disp label.name {
  float: left;
  width: 30%;
  margin-right: 4%;
  min-height: 26px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
}
@media (max-width: 767px) {
  #disp label.name {
    width: 100%;
    margin-right: 0;
  }
}
#disp input.text,
#disp textarea {
  float: right;
  width: 66%;
  background-color: #F0F0F0;
  min-height: 26px;
  box-sizing: border-box;
  padding: 2px 10px;
}
#disp input.text.fail,
#disp textarea.fail {
  border: 2px solid #ae2121;
}
#disp textarea {
  min-height: 100px;
  padding: 6px 10px;
}
@media (max-width: 767px) {
  #disp input.text,
  #disp textarea {
    width: 100%;
  }
}
#disp select {
  float: right;
  width: 66%;
  background-color: #F0F0F0;
  height: 26px;
}
@media (max-width: 767px) {
  #disp select {
    width: 100%;
  }
}
#disp input + label,
#disp input + label {
  display: block;
  padding-left: 30px;
}
#disp input.radio,
#disp input.checkbox {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  height: 20px;
  width: 20px;
}
.cb-sitemap-target {
  float: left;
  margin-bottom: 20px;
  color: #fff;
  padding: 5px 10px;
  background-color: #ae2121;
}
.cb-sitemap-contents {
  float: left;
  width: 100%;
}
.cb-sitemap-contents li {
  list-style: none;
  padding-left: 20px;
  position: relative;
}
.cb-sitemap-contents li:before {
  content: '•';
  position: absolute;
  left: 0;
  top: 0;
}
.cb-zoom-indicator {
  position: absolute;
  z-index: 2;
  right: 15px;
  bottom: 15px;
  width: 36px;
  height: 36px;
  border-radius: 30px;
  background-color: rgba(0, 0, 0, 0.5);
  background-size: 18px 18px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-zoom-white.svg);
  opacity: 0;
  transform: scale(0);
  transition: all 0.3s cubic-bezier(0.05, 0.8, 0.5, 1);
}
a.zoom .cb-zoom-indicator {
  opacity: 1;
  transform: scale(1);
}
@media (max-width: 1023px) {
  .cb-zoom-indicator {
    right: 10px;
    bottom: 10px;
    width: 24px;
    height: 24px;
    background-size: 12px 12px;
    opacity: 1;
    transform: scale(1);
  }
}
#disp.zoom {
  position: fixed;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  max-width: 100%;
  height: 100%;
  background: none;
  padding: 0 !important;
  display: block !important;
  transform: none;
}
#disp.zoom .disp-wrapper {
  padding: 0;
  background: none;
  height: 100%;
}
#disp.zoom div.head h2 {
  display: none;
}
#disp.zoom div.head div.ctrl {
  position: absolute;
  right: 30px;
  top: 30px;
  z-index: 2;
}
@media (max-width: 767px) {
  #disp.zoom div.head div.ctrl {
    top: 20px;
    right: 20px;
  }
}
#disp.zoom div.head div.ctrl > div {
  float: left;
  margin: 0 10px;
}
#disp.zoom div.head div.ctrl > div:first-child {
  margin-left: 0;
}
#disp.zoom div.head div.ctrl > div:last-child {
  margin-right: 0;
}
#disp.zoom div.head div.ctrl a {
  float: left;
  display: block;
  width: 20px;
  height: 20px;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-arrow-left-white.svg);
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent !important;
}
#disp.zoom div.head div.ctrl .next a {
  background-image: url(/images/cb-default/cb-arrow-right-white.svg);
}
#disp.zoom div.head div.ctrl .quit a {
  background-image: url(/images/cb-default/cb-cross-white.svg);
  background-size: 16px 16px;
}
#disp.zoom div.head div.ctrl a:hover {
  transform: scale(1.1);
}
#disp.zoom div.head div.ctrl a:active {
  transform: scale(1);
}
#disp.zoom div.body {
  float: left;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 80px 30px;
  margin: 0;
}
@media (max-width: 767px) {
  #disp.zoom div.body {
    padding: 60px 20px;
  }
}
#disp.zoom div.body > div {
  float: left;
  width: 100% !important;
  height: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#disp.zoom div.body a {
  float: left;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#disp.zoom div.body img {
  float: left;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}
#disp.zoom div.foot {
  display: none;
}
.ie11 #disp.zoom {
  position: absolute;
}
.ie11 #disp.zoom div.body,
.ie11 #disp.zoom div.body > div,
.ie11 #disp.zoom div.body a {
  height: auto;
  display: block;
}
.ie11 #disp.zoom div.body img {
  float: none;
  position: relative;
  height: auto;
  display: block;
  margin: 0 auto;
}
#disp.srch {
  display: block !important;
}
#disp.srch h2 {
  display: none;
}
#disp.srch .disp-wrapper {
  background: none;
  padding: 0;
}
#disp.srch .head .ctrl a {
  background-image: url(/images/cb-default/cb-cross-white.svg);
}
#disp.srch div.body {
  margin: 0;
}
#disp.srch fieldset {
  margin: 0;
}
#disp.srch label.name {
  display: none;
}
#disp.srch input.text {
  font-family: 'Gantari', sans-serif;
  background-color: transparent;
  margin-top: 40px;
  width: 100%;
  color: #fff;
  font-size: 50px;
  line-height: 1;
  border-bottom: 1px solid #fff;
  padding: 20px 0;
  -webkit-font-smoothing: antialiased;
  appearance: none;
}
@media (max-width: 767px) {
  #disp.srch input.text {
    font-size: 18px;
  }
}
.cb-result {
  float: left;
  width: 100%;
  color: #fff;
  font-size: 18px;
  text-align: left;
  -webkit-font-smoothing: antialiased;
}
.cb-result p {
  margin-top: 40px;
}
.cb-result a {
  color: #fff;
  text-decoration: none;
}
.cb-result a:hover {
  color: #fff;
  text-decoration: underline;
}
.cb-result table {
  float: left;
  width: 100%;
  border-collapse: collapse;
}
.cb-result tbody {
  width: 100%;
}
.cb-result th {
  padding-top: 20px;
  font-size: 24px;
  line-height: 1;
  text-align: left;
}
.cb-result th:first-child {
  padding-right: 40px;
  width: 80px;
}
.cb-result td {
  padding-top: 10px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
}
.cb-result th,
.cb-result td {
  vertical-align: middle;
}
@media (max-width: 767px) {
  .cb-result {
    font-size: 14px;
  }
  .cb-result th {
    padding-top: 10px;
    font-size: 18px;
  }
  .cb-result th:first-child {
    padding-right: 10px;
    width: 54px;
  }
  .cb-result td {
    padding-bottom: 10px;
    padding-top: 5px;
  }
}
.unit.form form {
  float: left;
  width: 100%;
}
.cb-form-required {
  float: left;
  width: 100%;
}
.unit.form fieldset {
  float: left;
  width: 100%;
  position: relative;
}
.unit.form div.ctrl {
  float: left;
  width: 100%;
}
.unit.form div.ctrl.fail {
  box-sizing: border-box;
  padding: 5px;
  background-color: #e7bcbc;
}
@media (min-width: 768px) {
  .unit.form .tile div.ctrl {
    float: right;
    width: 66%;
  }
}
.unit.form .name {
  float: left;
  width: 100%;
  padding-bottom: 5px;
}
@media (min-width: 768px) {
  .unit.form .tile .name {
    width: 30%;
    min-height: 40px;
    padding-top: 7.5px;
    padding-bottom: 7.5px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
  }
}
.unit.form input.text,
.unit.form textarea {
  float: left;
  width: 100%;
  box-sizing: border-box;
  background: #F0F0F0;
  min-height: 40px;
  padding: 5px 10px;
  appearance: none;
}
.unit.form input.text.fail,
.unit.form textarea.fail {
  background-color: #e7bcbc;
}
.unit.form textarea {
  padding: 10px;
}
@media (min-width: 768px) {
  .unit.form .tile input.text,
  .unit.form .tile textarea {
    float: right;
    width: 66%;
  }
}
.unit.form div.tick div.ctrl > div {
  float: left;
  width: 100%;
  position: relative;
  padding: 4px 0;
}
@media (min-width: 768px) {
  .unit.form div.tick.tile div.ctrl > div {
    padding-top: 7.5px;
    padding-bottom: 7.5px;
  }
}
.unit.form div.tick label {
  float: left;
  width: 100%;
  box-sizing: border-box;
  padding-left: 26px;
}
.unit.form div.tick input {
  position: absolute;
  left: 0;
  top: 4px;
  width: 18px;
  margin-right: 6px;
  height: 25px;
  vertical-align: top;
}
@media (min-width: 768px) {
  .unit.form div.tick.tile input {
    top: 7.5px;
  }
}
.unit.form select {
  float: left;
  width: 100%;
  font-family: 'Gantari', sans-serif;
  font-size: 21px;
  line-height: 1.19047619;
  background: #F0F0F0;
  height: 40px;
  border: 1px solid var(--s-textcolor);
  padding: 5px;
}
.unit.form .ship fieldset > div {
  float: left;
  width: 100%;
}
.unit.form .ship fieldset > div.fail {
  box-sizing: border-box;
  padding: 5px;
  background-color: #e7bcbc;
}
.unit.form .ship div.chop {
  float: left;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}
.unit.form .ship div.chop input {
  float: left;
  width: 100%;
  display: block;
  font-size: 12px;
}
.unit.form .ship div.chop span {
  float: left;
}
.unit.form .ship div.chop a {
  float: left;
  display: block;
  margin-left: 10px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent !important;
  width: 12px;
  height: 12px;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-cross-black.svg);
}
@media (min-width: 768px) {
  .unit.form .ship.tile fieldset > div {
    float: right;
    width: 66%;
    min-height: 40px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
  }
}
.unit.form .submit {
  float: right;
  margin-top: var(--spacePart);
  margin-bottom: var(--spacePart);
  display: inline-block;
  background-color: var(--s-btn-bg);
  font-size: 26px;
  line-height: 1;
  font-weight: bold;
  cursor: pointer;
  color: var(--s-btn-color);
  min-width: 112px;
  padding: 9px 26px 26px 9px;
  appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-decoration: none;
  position: relative;
  transition: all 0.4s;
}
.unit.form .submit:before,
.unit.form .submit:after {
  content: '';
  position: absolute;
  left: 12px;
  bottom: 12px;
  width: 30px;
  height: 9px;
  background-color: var(--s-btn-deco-color);
  transition: all 0.4s;
}
.unit.form .submit:hover,
.unit.form .submit:focus {
  background-color: var(--s-btn-bg-hover);
  color: var(--s-btn-color-hover);
}
.unit.form .submit:hover:after,
.unit.form .submit:focus:after {
  background-color: var(--s-btn-deco-color-hover);
}
@media (max-width: 1023px) {
  .unit.form .submit {
    padding: 5px 30px 30px 12px;
    font-size: 21px;
    line-height: 1.19047619;
  }
}
.calendar {
  position: absolute !important;
  z-index: 2;
  left: 34% !important;
  top: 100% !important;
  right: unset !important;
  background: #fff;
  width: 200px;
  margin: 10px 0 0 0 !important;
  box-shadow: 0 0 6px rgba(160, 160, 160, 0.4);
  font-size: 14px;
  line-height: 1.14285714;
  padding: 20px;
}
.calendar table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}
.calendar th,
.calendar td {
  text-align: center;
  padding: 2px;
}
.calendar tr.month th {
  padding-bottom: 10px;
}
.calendar tr.month th:first-child a,
.calendar tr.month th:last-child a {
  float: left;
  display: block;
  width: 16px;
  height: 16px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent !important;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-arrow-left-black.svg);
}
.calendar tr.month th:last-child a {
  float: right;
  background-image: url(/images/cb-default/cb-arrow-right-black.svg);
}
.calendar td.today a {
  color: var(--s-textcolor);
  text-decoration: underline;
}
.unit.form .recaptcha-info {
  color: #aaa;
}
.unit.form .recaptcha-info a {
  color: #aaa;
}
.unit.form .recaptcha-info a:hover,
.unit.form .recaptcha-info a:focus {
  text-decoration: underline;
}
.unit.form .part.fail {
  font-size: 16px;
  line-height: 1.25;
  border-left: 4px solid #ae2121;
  box-sizing: border-box;
  padding: 20px;
  background-color: #e7bcbc;
  color: #ae2121;
}
.unit.form div.cb-form-sent {
  float: left;
  width: 100%;
  margin-top: var(--spacePart);
  margin-bottom: var(--spacePart);
}
.unit.form div.cb-form-sent a {
  float: right;
  color: #5abb55;
}
#disp.two-step-verification div.body {
  overflow: hidden;
}
#disp.two-step-verification p {
  float: left;
  width: 100%;
}
.two-step-verification-container {
  float: left;
  width: 100%;
  min-width: 300px;
  margin-top: 10px;
  height: 350px;
  position: relative;
}
.two-step-verification-container a {
  display: inline-block;
  background-color: var(--s-btn-bg);
  font-size: 26px;
  line-height: 1;
  font-weight: bold;
  cursor: pointer;
  color: var(--s-btn-color);
  min-width: 112px;
  padding: 9px 26px 26px 9px;
  appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-decoration: none;
  position: relative;
  transition: all 0.4s;
}
.two-step-verification-container a:before,
.two-step-verification-container a:after {
  content: '';
  position: absolute;
  left: 12px;
  bottom: 12px;
  width: 30px;
  height: 9px;
  background-color: var(--s-btn-deco-color);
  transition: all 0.4s;
}
.two-step-verification-container a:hover,
.two-step-verification-container a:focus {
  background-color: var(--s-btn-bg-hover);
  color: var(--s-btn-color-hover);
}
.two-step-verification-container a:hover:after,
.two-step-verification-container a:focus:after {
  background-color: var(--s-btn-deco-color-hover);
}
@media (max-width: 1023px) {
  .two-step-verification-container a {
    padding: 5px 30px 30px 12px;
    font-size: 21px;
    line-height: 1.19047619;
  }
}
.two-step-verification__div {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: #fff;
  background-size: 100px 100px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-loader-black.svg);
}
.two-step-verification-container.loaded .two-step-verification__div {
  background-image: none;
}
.table {
  float: left;
  width: 100%;
  text-align: left;
  border-collapse: collapse;
}
.table-layout-fixed .table {
  table-layout: fixed;
}
.table tr {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}
.table-head tr {
  border-top: none;
}
.table th {
  font-weight: normal;
  padding: 5px 10px;
  vertical-align: top;
}
.table th.init {
  padding-left: 0;
}
.table th.exit {
  padding-right: 0;
}
.table th.align-left {
  text-align: left;
}
.table th.align-center {
  text-align: center;
}
.table th.align-right {
  text-align: right;
}
.table--headline th {
  font-weight: bold;
}
.table td {
  padding: 5px 10px;
  vertical-align: top;
  font-size: 21px;
  line-height: 1.19047619;
  color: var(--s-textcolor);
  font-weight: 300;
}
.table td.init {
  padding-left: 0;
}
.table td.exit {
  padding-right: 0;
}
.table td.align-left {
  text-align: left;
}
.table td.align-center {
  text-align: center;
}
.table td.align-right {
  text-align: right;
}
#edit .table td {
  border-left: 1px dashed #e0e0e0;
  border-right: 1px dashed #e0e0e0;
}
.table--footer .table-foot td {
  font-weight: bold;
}
@media (max-width: 767px) {
  .part--table .table {
    font-size: 2.73794003vw;
  }
}
html,
body {
  float: left;
  width: 100%;
  min-height: 100vh;
}
main {
  float: left;
  width: 100%;
  position: relative;
  flex: 1 0 auto;
}
#home {
  float: left;
}
.logo {
  width: auto;
  height: 100%;
}
#head {
  float: left;
  width: 100%;
}
.wrapper {
  float: left;
  width: 100%;
  min-height: 100vh;
  background-color: #fff;
  font-family: 'Gantari', sans-serif;
  font-size: 21px;
  line-height: 1.19047619;
  color: var(--s-textcolor);
  overflow: hidden;
  hyphens: none;
  display: flex;
  flex-direction: column;
  -webkit-font-smoothing: antialiased;
}
.desk {
  *zoom: 1;
  margin: 0 auto;
  max-width: var(--deskMaxWidth);
  width: var(--deskWidth);
  position: relative;
}
.desk:before,
.desk:after {
  display: table;
  content: '';
}
.desk:after {
  clear: both;
}
.section--header .desk,
.section--multimood .desk {
  --deskWidth: 1440px;
}
.section {
  float: left;
  width: 100%;
  position: relative;
}
.section--two {
  margin: var(--spaceUnit) 0;
  background-color: #f0f3f8;
}
.section--teaserSlider {
  padding: var(--spaceTotal) 0;
}
.section--header {
  background-color: #FFFFFF;
}
.container--head {
  float: left;
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 15px;
}
.container--navi {
  float: left;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: flex-end;
}
.navigation {
  float: left;
}
.serviceLinks {
  float: left;
  display: flex;
  gap: 10px 24px;
}
.serviceLinks--desktop {
  flex-wrap: wrap;
}
.serviceLinks--mobile {
  flex-direction: column;
  box-sizing: border-box;
  padding: 0 var(--deskSpace);
  margin-bottom: 30px;
}
@media (max-width: 1023px) {
  .serviceLinks--desktop {
    display: none;
  }
}
@media (max-width: 767px) {
  .serviceLinks {
    flex-direction: column;
  }
}
.section--multimood {
  isolation: isolate;
}
.container--mood {
  float: left;
  aspect-ratio: 1.77777778;
  position: relative;
}
@supports not (aspect-ratio: 16 /  9) {
  .container--mood:before {
    float: left;
    padding-top: 56.25%;
    content: '';
  }
  .container--mood:after {
    display: block;
    content: '';
    clear: both;
  }
}
.container--mood #expo {
  position: absolute;
  left: 0;
  top: 0;
}
.container--mood .moodContent {
  float: right;
  z-index: 2;
  position: relative;
  box-sizing: border-box;
}
.mood-background {
  position: absolute;
  left: 0;
  top: 0;
  background-color: #f0f3f8;
  z-index: -1;
}
.section--intro {
  isolation: isolate;
  --s-btn-bg-hover: #FFFFFF;
  --s-btn-color-hover: #023E84;
  --s-btn-deco-color-hover: #F6A124;
  --s-linkcolor-hover: #FFFFFF;
  --s-textcolor: #FFFFFF;
}
.intro-background {
  position: absolute;
  right: 0;
  top: 35px;
  background-color: #f0f3f8;
  z-index: -1;
}
.container--intro {
  float: left;
  box-sizing: border-box;
  background-color: #023E84;
}
.container--intro:after {
  content: '';
  position: absolute;
  left: 100%;
  top: 0;
  background-color: #023E84;
  z-index: -1;
}
.designelement--triangles {
  float: left;
  position: absolute;
  left: -28px;
  isolation: isolate;
  z-index: -1;
}
.layout1 .designelement--triangles {
  top: 100px;
}
.layout2 .designelement--triangles,
.layout3 .designelement--triangles {
  bottom: 100px;
}
.layout4 .designelement--triangles {
  bottom: 200px;
}
.layout5 .designelement--triangles {
  top: 500px;
}
.container--facts {
  float: left;
  background-color: #023E84;
  isolation: isolate;
  box-sizing: border-box;
  position: relative;
  --s-textcolor: #FFFFFF;
  --s-btn-bg-hover: #FFFFFF;
  --s-btn-color-hover: #023E84;
  --s-btn-deco-color-hover: #F6A124;
  --s-linkcolor-hover: #FFFFFF;
}
.container--facts:after {
  content: '';
  position: absolute;
  left: 100%;
  top: 0;
  background-color: #023E84;
  z-index: -1;
}
.container--facts .facts {
  float: left;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 0 30px;
}
@media (max-width: 767px) {
  .container--facts .facts {
    flex-direction: column;
    align-items: flex-start;
  }
}
.container--blogContent {
  float: left;
  width: 100%;
  display: grid;
  gap: 0 70px;
  align-items: flex-start;
}
@media (max-width: 1023px) {
  .container--blogContent {
    display: flex;
    flex-wrap: wrap;
    gap: 0 30px;
  }
}
.unit--blogImages {
  margin: var(--spaceUnit) 0;
}
.unit--blogImages .part + .part {
  margin-top: 0;
}
#edit .unit--blogImages {
  min-height: 100px;
}
#view .unit--blogImages.is-empty {
  display: none;
}
.unit--blogContent {
  margin: var(--spaceUnit) 0;
}
#edit .unit--blogContent {
  min-height: 100px;
}
#view .unit--blogContent.is-empty {
  display: none;
}
.section--footer {
  isolation: isolate;
}
.footer-background {
  position: absolute;
  right: -70px;
  width: 100vw;
  height: calc(100% + 35px);
  background-color: #f0f3f8;
  z-index: -1;
}
@media (max-width: 1360px) {
  .footer-background {
    right: 0;
  }
}
.footarea {
  float: left;
  width: 100%;
  background-color: #023E84;
  display: grid;
  grid-gap: 0 30px;
  position: relative;
  box-sizing: border-box;
}
.footarea:after {
  content: '';
  position: absolute;
  left: 100%;
  top: 0;
  width: 100vw;
  height: 100%;
  background-color: #023E84;
  z-index: -1;
}
.footpart--two {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-end;
  gap: var(--spacePart) 50px;
}
.toplink {
  display: inline-block;
  background-color: var(--s-btn-bg);
  font-size: 26px;
  line-height: 1;
  font-weight: bold;
  cursor: pointer;
  color: var(--s-btn-color);
  min-width: 112px;
  padding: 9px 26px 26px 9px;
  appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-decoration: none;
  position: relative;
  transition: all 0.4s;
  --s-btn-bg-hover: #FFFFFF;
  --s-btn-color-hover: #023E84;
  --s-btn-deco-color-hover: #F6A124;
}
.toplink:before,
.toplink:after {
  content: '';
  position: absolute;
  left: 12px;
  bottom: 12px;
  width: 30px;
  height: 9px;
  background-color: var(--s-btn-deco-color);
  transition: all 0.4s;
}
.toplink:hover,
.toplink:focus {
  background-color: var(--s-btn-bg-hover);
  color: var(--s-btn-color-hover);
}
.toplink:hover:after,
.toplink:focus:after {
  background-color: var(--s-btn-deco-color-hover);
}
@media (max-width: 1023px) {
  .toplink {
    padding: 5px 30px 30px 12px;
    font-size: 21px;
    line-height: 1.19047619;
  }
}
.backlink {
  display: inline-block;
  background-color: var(--s-btn-bg);
  font-size: 26px;
  line-height: 1;
  font-weight: bold;
  cursor: pointer;
  color: var(--s-btn-color);
  min-width: 112px;
  padding: 9px 26px 26px 9px;
  appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-decoration: none;
  position: relative;
  transition: all 0.4s;
  float: right;
  margin: var(--spaceTotal) 0;
}
.backlink:before,
.backlink:after {
  content: '';
  position: absolute;
  left: 12px;
  bottom: 12px;
  width: 30px;
  height: 9px;
  background-color: var(--s-btn-deco-color);
  transition: all 0.4s;
}
.backlink:hover,
.backlink:focus {
  background-color: var(--s-btn-bg-hover);
  color: var(--s-btn-color-hover);
}
.backlink:hover:after,
.backlink:focus:after {
  background-color: var(--s-btn-deco-color-hover);
}
@media (max-width: 1023px) {
  .backlink {
    padding: 5px 30px 30px 12px;
    font-size: 21px;
    line-height: 1.19047619;
  }
}
.services {
  float: left;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.footlogo {
  float: left;
}
.footlogo__img {
  height: 100%;
}
#cb-cookie-warning {
  position: fixed;
  left: 40px;
  bottom: 40px;
  max-width: calc(100vw - 60px);
  width: 500px;
  z-index: 2100;
  font-family: 'Gantari', sans-serif;
  color: var(--s-textcolor);
  background-color: #fff;
  box-shadow: 0 0 4px rgba(120, 120, 120, 0.4);
}
#cb-cookie-warning a {
  text-decoration: underline;
}
#cb-cookie-warning.cb-cookie-warning--hidden {
  display: none;
}
@media (max-width: 1023px) {
  #cb-cookie-warning {
    left: 24px;
    bottom: 24px;
    max-width: calc(100vw - 48px);
  }
}
@media (max-width: 1023px) {
  #cb-cookie-warning {
    left: 16px;
    bottom: 16px;
    max-width: calc(100vw - 32px);
  }
}
.cb-cookie-warning--container {
  float: left;
  width: 100%;
}
.cb-cookie-warning--text {
  float: left;
  width: 100%;
  box-sizing: border-box;
  padding: 30px;
  font-size: 21px;
  line-height: 1.19047619;
  color: var(--s-textcolor);
  font-weight: 300;
}
@media (max-width: 767px) {
  .cb-cookie-warning--text {
    padding: 16px;
  }
}
.cb-cookie-warning--actions {
  float: left;
  width: 100%;
  display: flex;
  gap: 30px;
  box-sizing: border-box;
  padding: 0 30px 30px;
}
@media (max-width: 767px) {
  .cb-cookie-warning--actions {
    padding: 0 16px 16px;
    gap: 16px;
  }
}
.cb-cookie-warning__button {
  float: left;
  display: inline-block;
  background-color: var(--s-btn-bg);
  font-size: 26px;
  line-height: 1;
  font-weight: bold;
  cursor: pointer;
  color: var(--s-btn-color);
  min-width: 112px;
  padding: 9px 26px 26px 9px;
  appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-decoration: none;
  position: relative;
  transition: all 0.4s;
}
.cb-cookie-warning__button:before,
.cb-cookie-warning__button:after {
  content: '';
  position: absolute;
  left: 12px;
  bottom: 12px;
  width: 30px;
  height: 9px;
  background-color: var(--s-btn-deco-color);
  transition: all 0.4s;
}
.cb-cookie-warning__button:hover,
.cb-cookie-warning__button:focus {
  background-color: var(--s-btn-bg-hover);
  color: var(--s-btn-color-hover);
}
.cb-cookie-warning__button:hover:after,
.cb-cookie-warning__button:focus:after {
  background-color: var(--s-btn-deco-color-hover);
}
@media (max-width: 1023px) {
  .cb-cookie-warning__button {
    padding: 5px 30px 30px 12px;
    font-size: 21px;
    line-height: 1.19047619;
  }
}
a {
  color: var(--s-linkcolor);
  text-decoration: none;
}
a:hover,
a:focus {
  color: var(--s-linkcolor-hover);
}
h1 {
  font-size: 53px;
  line-height: 1.16981132;
  color: var(--s-textcolor);
  font-family: 'Noto Serif', serif;
  position: relative;
  padding-bottom: 20px;
  hyphens: auto;
}
h1:after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 30px;
  height: 9px;
  background-color: #F6A124;
}
@media (max-width: 1023px) {
  h1 {
    font-size: 28px;
    line-height: 1.14285714;
  }
}
h2 {
  font-size: 53px;
  line-height: 1.16981132;
  color: var(--s-textcolor);
  font-family: 'Noto Serif', serif;
  position: relative;
  padding-bottom: 20px;
  hyphens: auto;
}
h2:after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 30px;
  height: 9px;
  background-color: #F6A124;
}
@media (max-width: 1023px) {
  h2 {
    font-size: 28px;
    line-height: 1.14285714;
  }
}
h3 {
  font-size: 35px;
  line-height: 1.17142857;
  color: var(--s-textcolor);
  font-family: 'Noto Serif', serif;
  position: relative;
  padding-bottom: 20px;
}
.area--one h3:after,
.area--intro h3:after,
.section--teaserSlider h3:after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 30px;
  height: 9px;
  background-color: #F6A124;
}
@media (max-width: 1023px) {
  h3 {
    font-size: 28px;
    line-height: 1.14285714;
  }
}
h4 {
  font-size: 26px;
  line-height: 1.19230769;
  color: var(--s-textcolor);
}
@media (max-width: 1023px) {
  h4 {
    font-size: 21px;
    line-height: 1.19047619;
  }
}
.norm {
  font-size: 21px;
  line-height: 1.19047619;
  color: var(--s-textcolor);
  font-weight: 300;
}
.loud {
  font-size: 24px;
  line-height: 1.26086957;
  color: var(--s-textcolor);
  font-weight: 300;
}
@media (max-width: 1023px) {
  .loud {
    font-size: 22px;
    line-height: 1.18181818;
  }
}
.pale {
  font-size: 219px;
  line-height: 0.79908676;
  letter-spacing: 0.02em;
  color: var(--s-folding-number-color);
  font-family: 'Noto Serif', serif;
  transition: all 0.4s;
}
@media (max-width: 1023px) {
  .pale {
    font-size: 100px;
    line-height: 0.8;
  }
}
.skew {
  font-family: 'Noto Serif', serif;
  font-size: 28px;
  line-height: 1.14285714;
  color: var(--s-textcolor);
  font-style: italic;
}
@media (max-width: 1023px) {
  .skew {
    font-size: 26px;
    line-height: 1.19230769;
  }
}
.cb-image-caption {
  font-size: 19px;
  line-height: 1.21052632;
  color: var(--s-textcolor);
}
.sep {
  float: left;
  font-size: 17px;
  line-height: 1.17647059;
  color: #F6A124;
}
.part--moodSlogan span {
  font-size: 53px;
  line-height: 1.16981132;
  color: #FFFFFF;
  text-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
  text-align: right;
  font-weight: bold;
}
@media (max-width: 1023px) {
  .part--moodSlogan span {
    font-size: 22px;
    line-height: 1.08333333;
  }
}
.area--one {
  display: grid;
  gap: 0 30px;
}
.area--one .unitOne .unit__body {
  display: grid;
  grid-gap: 0 30px;
}
.area--one .unitTwo {
  transition: all 0.4s;
  box-sizing: border-box;
  isolation: isolate;
}
.area--one .unitTwo:before {
  content: '';
  position: absolute;
  background-color: #f0f3f8;
  top: 35px;
  right: 0;
  transition: all 0.4s;
  z-index: -2;
}
#edit .area--one .unitTwo:before {
  background-color: transparent;
}
.area--one .unitTwo:after {
  content: '';
  position: absolute;
  background-color: #023E84;
  top: 0;
  transition: all 0.4s;
  z-index: -2;
}
#edit .area--one .unitTwo:after {
  background-color: transparent;
}
.area--one .unitTwo--positionRight:before {
  right: 0;
}
.area--one .unitTwo--positionRight:after {
  left: 0;
}
.area--one .unitTwo--positionLeft:before {
  left: 0;
}
.area--one .unitTwo--positionLeft:after {
  right: 0;
}
.area--one .unitTwo.unit--foldClosed {
  --s-folding-number-color: #1b5190;
  --s-textcolor: #FFFFFF;
}
#view .area--one .unitTwo.unit--foldOpen:before,
#view .area--one .unitTwo.unit--foldOpen:after {
  background-color: transparent;
}
.area--one .unitTwo.unit--foldOpen .less:before {
  opacity: 1;
}
.area--one .unitTwo .more {
  display: block !important;
  overflow: hidden;
  transition: all 0.4s;
  box-sizing: border-box;
}
.area--one .unitTwo .less {
  box-sizing: border-box;
}
.area--one .unitTwo .less:before {
  content: '';
  float: left;
  position: absolute;
  top: 200px;
  left: calc(((100vw - 1160px) / 2) * -1 + 112px);
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/designelement-triangles.svg);
  opacity: 0;
  transition: all 0.4s;
  z-index: -1;
}
#view .area--one .unitTwo .less .part.text:first-child {
  width: auto;
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 0;
}
#view .area--one .unitTwo .less .part--link:last-child {
  width: auto;
  position: absolute;
  bottom: 70px;
  right: 15px;
  margin: 0;
}
@media (min-width: 1440px) {
  #view .area--one .unitTwo .less .part--link:last-child {
    right: 140px;
  }
}
.area--one .unitTwo .fold-toggle {
  display: inline-block;
  background-color: var(--s-btn-bg);
  font-size: 26px;
  line-height: 1;
  font-weight: bold;
  cursor: pointer;
  color: var(--s-btn-color);
  min-width: 112px;
  padding: 9px 26px 26px 9px;
  appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-decoration: none;
  position: relative;
  transition: all 0.4s;
  float: right;
  --s-btn-color-hover: #023E84;
  --s-btn-bg-hover: #F6A124;
}
.area--one .unitTwo .fold-toggle:before,
.area--one .unitTwo .fold-toggle:after {
  content: '';
  position: absolute;
  left: 12px;
  bottom: 12px;
  width: 30px;
  height: 9px;
  background-color: var(--s-btn-deco-color);
  transition: all 0.4s;
}
.area--one .unitTwo .fold-toggle:hover,
.area--one .unitTwo .fold-toggle:focus {
  background-color: var(--s-btn-bg-hover);
  color: var(--s-btn-color-hover);
}
.area--one .unitTwo .fold-toggle:hover:after,
.area--one .unitTwo .fold-toggle:focus:after {
  background-color: var(--s-btn-deco-color-hover);
}
@media (max-width: 1023px) {
  .area--one .unitTwo .fold-toggle {
    padding: 5px 30px 30px 12px;
    font-size: 21px;
    line-height: 1.19047619;
  }
}
#view .area--one .unitTwo .fold-toggle.fold-toggle--open {
  color: transparent;
}
#view .area--one .unitTwo .fold-toggle.fold-toggle--open:before {
  bottom: 20px;
  transform: rotate(-45deg);
}
#view .area--one .unitTwo .fold-toggle.fold-toggle--open:after {
  bottom: 20px;
  transform: rotate(45deg);
}
.area--one .unitTwo .unit__foot {
  display: none;
}
@media (max-width: 1023px) {
  .area--one .unitTwo:before {
    top: var(--spaceUnit);
    right: 0;
  }
  .area--one .unitTwo .less:before {
    top: 120px;
    left: -35px;
  }
  .area--one .unitTwo .less .part--link:last-child {
    position: absolute;
    bottom: 25px;
    right: 15px;
  }
  .area--one .unitTwo .fold-toggle:before {
    right: 0;
  }
}
.unitThree {
  box-sizing: border-box;
  overflow: hidden;
  isolation: isolate;
  --spacePart: 10px;
}
.unitThree .unit__background {
  position: absolute;
  width: 100%;
  overflow: hidden;
  aspect-ratio: 1;
  z-index: -1;
}
@supports not (aspect-ratio: 1 /  1) {
  .unitThree .unit__background:before {
    float: left;
    padding-top: 100%;
    content: '';
  }
  .unitThree .unit__background:after {
    display: block;
    content: '';
    clear: both;
  }
}
.unitThree .unit__background * {
  height: 100%;
}
.unitThree .unit__background img {
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: cover;
  object-position: center;
}
.unitThree .more {
  aspect-ratio: 1;
  background-color: rgba(246, 161, 36, 0.6);
  display: flex !important;
  flex-direction: column;
  justify-content: flex-end;
  box-sizing: border-box;
  padding: 30px;
  transition: all 0.4s;
}
@supports not (aspect-ratio: 1 /  1) {
  .unitThree .more:before {
    float: left;
    padding-top: 100%;
    content: '';
  }
  .unitThree .more:after {
    display: block;
    content: '';
    clear: both;
  }
}
.unitThree .more .part.text:first-child {
  padding-bottom: 20px;
}
.unitThree .more .part.text:first-child:after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 30px;
  height: 9px;
  background-color: #FFFFFF;
}
#view .unitThree .more {
  position: absolute;
  left: 0;
  top: 0;
  transform: translateY(100%);
}
.unitThree .less {
  aspect-ratio: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
@supports not (aspect-ratio: 1 /  1) {
  .unitThree .less:before {
    float: left;
    padding-top: 100%;
    content: '';
  }
  .unitThree .less:after {
    display: block;
    content: '';
    clear: both;
  }
}
.unitThree .less .part {
  transition: all 0.4s;
}
.unitThree .less .part.text:last-child {
  margin: 0;
  padding: 10px 30px;
  box-sizing: border-box;
  background-color: rgba(246, 161, 36, 0.6);
}
#view .unitThree .less .part.text:first-child {
  position: unset;
  z-index: 2;
}
#view .unitThree.unit--foldOpen .more {
  transform: translateY(0);
}
#view .unitThree.unit--foldOpen .less .part:not(:first-child) {
  opacity: 0;
}
.unitThree .fold-toggle {
  isolation: isolate;
  display: inline-block;
  text-decoration: none!important;
  box-sizing: border-box;
  color: var(--s-textcolor);
  transition: all 0.4s;
  height: 40px;
}
.unitThree .fold-toggle:before {
  content: '';
  position: absolute;
  z-index: 11;
  width: 40px;
  height: 40px;
  top: calc(50% - 24px);
  right: 30px;
  border-radius: 100%;
  box-sizing: border-box;
  background-color: rgba(246, 161, 36, 0.6);
  background-size: 31px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/teamfold-toggle-open.svg);
  transition: all 0.4s;
  transform-origin: center;
}
#view .unitThree .fold-toggle {
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent !important;
  position: absolute;
  right: 30px;
  top: 30px;
  width: 40px;
}
#view .unitThree .fold-toggle:before {
  right: 0;
  top: 0;
}
.unitThree .fold-toggle.fold-toggle--open:before {
  background-image: url(/images/teamfold-toggle-close.svg);
}
.unitThree .unit__foot {
  display: none;
}
.unitThree .part.text a {
  --s-linkcolor: #023E84;
}
.area--one .unitFour {
  display: flex;
  align-items: flex-end;
  gap: 0 70px;
  flex-direction: row-reverse;
  box-sizing: border-box;
}
.area--one .unitFour:before,
.area--one .unitFour:after {
  content: '';
  position: absolute;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}
.area--one .unitFour:before {
  top: var(--spacePart);
  left: 0;
  background-image: url(/images/quote-mark-open.svg);
}
.area--one .unitFour:after {
  bottom: var(--spacePart);
  right: 0;
  background-image: url(/images/quote-mark-close.svg);
}
@media (max-width: 1023px) {
  .area--one .unitFour {
    gap: 0 30px;
  }
}
@media (max-width: 767px) {
  .area--one .unitFour {
    flex-direction: column;
  }
}
.area--one .unitFive .unit__body {
  display: grid;
  grid-gap: 0 30px;
}
.area--intro .unit .unit__body {
  display: grid;
  grid-gap: 0 30px;
}
.unit--quicklinks {
  margin: 0;
}
#edit .unit--quicklinks {
  min-height: 100px;
}
.unit--quicklinks .part.link {
  --spacePart: 5px;
  font-size: 19px;
  line-height: 1.21052632;
}
.unit--quicklinks .part.link .open {
  --s-linkcolor: #FFFFFF;
  --s-linkcolor-hover: #F6A124;
  font-weight: 300;
}
.part--center.part.text,
.part--center.part.link {
  text-align: center;
}
.part--center.part.pict {
  display: flex;
  align-items: center;
}
.part--center.part.pict .cb-image-container {
  display: flex;
  justify-content: center;
}
.part--right.part.link {
  text-align: right;
}
.partEffect--coloredHover {
  filter: grayscale(1);
  transition: all 0.4s;
}
.partEffect--coloredHover:hover,
.partEffect--coloredHover:focus {
  filter: grayscale(0);
}
.area--one .part.link .open,
.area--intro .part.link .open {
  display: inline-block;
  background-color: var(--s-btn-bg);
  font-size: 26px;
  line-height: 1;
  font-weight: bold;
  cursor: pointer;
  color: var(--s-btn-color);
  min-width: 112px;
  padding: 9px 26px 26px 9px;
  appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-decoration: none;
  position: relative;
  transition: all 0.4s;
}
.area--one .part.link .open:before,
.area--intro .part.link .open:before,
.area--one .part.link .open:after,
.area--intro .part.link .open:after {
  content: '';
  position: absolute;
  left: 12px;
  bottom: 12px;
  width: 30px;
  height: 9px;
  background-color: var(--s-btn-deco-color);
  transition: all 0.4s;
}
.area--one .part.link .open:hover,
.area--intro .part.link .open:hover,
.area--one .part.link .open:focus,
.area--intro .part.link .open:focus {
  background-color: var(--s-btn-bg-hover);
  color: var(--s-btn-color-hover);
}
.area--one .part.link .open:hover:after,
.area--intro .part.link .open:hover:after,
.area--one .part.link .open:focus:after,
.area--intro .part.link .open:focus:after {
  background-color: var(--s-btn-deco-color-hover);
}
@media (max-width: 1023px) {
  .area--one .part.link .open,
  .area--intro .part.link .open {
    padding: 5px 30px 30px 12px;
    font-size: 21px;
    line-height: 1.19047619;
  }
}
.part--standAloneText {
  width: 100%;
}
.part--standAloneFact {
  width: auto;
  text-align: right;
  margin: var(--spacePart) 0;
}
.part--standAloneFact .part__content {
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.part--standAloneFact h1,
.part--standAloneFact h2,
.part--standAloneFact h3,
.part--standAloneFact h4 {
  padding-bottom: 0;
}
.part--standAloneFact h1:after,
.part--standAloneFact h2:after,
.part--standAloneFact h3:after,
.part--standAloneFact h4:after {
  content: none;
}
.part--standAloneFact h2 {
  --s-textcolor: #F6A124;
}
@media (max-width: 767px) {
  .part--standAloneFact {
    text-align: left;
  }
}
.part--socialLinks {
  float: left;
}
.part--socialLinks .iconLinks__content {
  float: left;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 18px;
}
.part--socialLinks .iconLinks__icon {
  float: left;
  width: 36px;
  height: 36px;
  mask-size: 100% 100%;
  mask-position: 50% 50%;
  mask-repeat: no-repeat;
  background-color: #F6A124;
  transition: all 0.4s;
}
.part--socialLinks .iconLinks__icon--instagram {
  mask-image: url(/images/icon-instagram-2.svg);
}
.part--socialLinks .iconLinks__icon--facebook {
  mask-image: url(/images/icon-facebook.svg);
}
.part--socialLinks .iconLinks__icon--tiktok {
  mask-image: url(/images/icon-tiktok.svg);
}
.part--socialLinks .iconLinks__icon--linkedin {
  mask-image: url(/images/icon-linkedin.svg);
}
.part--socialLinks .iconLinks__icon:hover,
.part--socialLinks .iconLinks__icon:focus {
  background-color: #FFFFFF;
}
.part--serviceLink .open {
  float: left;
  font-size: 17px;
  line-height: 1.17647059;
  font-weight: 300;
  padding: 1px 0 1px 30px;
  box-sizing: border-box;
  position: relative;
  transition: all 0.4s;
}
.part--serviceLink .open:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 22px;
  height: 22px;
  mask-size: 100% 100%;
  mask-position: 50% 50%;
  mask-repeat: no-repeat;
  background-color: var(--s-linkcolor);
  transition: all 0.4s;
}
.part--serviceLink .open:hover:before,
.part--serviceLink .open:focus:before {
  background-color: var(--s-linkcolor-hover);
}
.part--serviceLinkJobs .open:before {
  mask-image: url(/images/icon-jobs.svg);
}
.part--serviceLinkBlog .open:before {
  mask-image: url(/images/icon-blog.svg);
}
.part--serviceLinkContact .open:before {
  mask-image: url(/images/icon-contact.svg);
}
.part--footlink {
  float: none;
}
.part--footlink .open {
  float: left;
  font-size: 17px;
  line-height: 1.17647059;
  font-weight: 300;
  --s-linkcolor: #F6A124;
  --s-linkcolor-hover: #FFFFFF;
}
.part--copyright {
  width: 100%;
  font-size: 17px;
  line-height: 1.17647059;
  color: #F6A124;
  font-weight: 300;
}
.part--foottitle {
  --s-textcolor: #F6A124;
}
.part.cb-googlemaps.partWidth--1-1 .cb-googlemapscontainer {
  aspect-ratio: 4.26470588;
  height: 100% !important;
}
@supports not (aspect-ratio: 1160 /  272) {
  .part.cb-googlemaps.partWidth--1-1 .cb-googlemapscontainer:before {
    float: left;
    padding-top: 23.44827586%;
    content: '';
  }
  .part.cb-googlemaps.partWidth--1-1 .cb-googlemapscontainer:after {
    display: block;
    content: '';
    clear: both;
  }
}
.part.cb-googlemaps.partWidth--1-1 .cb-googlemapscontainer .cb-undraggable {
  height: 100% !important;
}
@media (max-width: 767px) {
  .part.cb-googlemaps.partWidth--1-1 .cb-googlemapscontainer {
    aspect-ratio: 2.14285714;
  }
  @supports not (aspect-ratio: 300 /  140) {
    .part.cb-googlemaps.partWidth--1-1 .cb-googlemapscontainer:before {
      float: left;
      padding-top: 46.66666667%;
      content: '';
    }
    .part.cb-googlemaps.partWidth--1-1 .cb-googlemapscontainer:after {
      display: block;
      content: '';
      clear: both;
    }
  }
}
@media (max-width: 1023px) {
  body.cb-toggle-target-active {
    overflow: hidden;
  }
  .section {
    transition: transform 0.6s cubic-bezier(0.05, 0.8, 0.5, 1);
  }
  body.cb-toggle-target-active .section {
    transform: translateY(200px);
  }
  body.cb-toggle-target-active .section.section--header {
    transform: none;
  }
  .navi {
    display: none;
  }
}
.togglenavigation {
  position: fixed;
  top: 15px;
  right: 30px;
  z-index: 2001;
  width: 36px;
  height: 24px;
  cursor: pointer;
}
.tline {
  position: absolute;
  top: 0;
  left: 0;
  width: 36px;
  height: 4px;
  background-color: #023E84;
  border-radius: 4px;
  transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.tline--2,
.tline--3 {
  top: 50%;
  transform: translateY(-50%);
}
.tline--4 {
  top: unset;
  bottom: 0;
}
body.cb-toggle-target-active .tline--1 {
  opacity: 0;
  transform: translateY(-10px);
}
body.cb-toggle-target-active .tline--2 {
  transform: translateY(-50%) rotate(-45deg);
}
body.cb-toggle-target-active .tline--3 {
  transform: translateY(-50%) rotate(45deg);
}
body.cb-toggle-target-active .tline--4 {
  opacity: 0;
  transform: translateY(10px);
}
.mobile-navigation {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2000;
  width: 100%;
  height: 100%;
  background-color: #fff;
  overflow-y: scroll;
  transform: translateY(-100%);
  transition: all 0.6s cubic-bezier(0.05, 0.8, 0.5, 1);
}
body.cb-toggle-target-active .mobile-navigation {
  transform: translateY(0);
}
.mobile-navi-animation {
  float: left;
  width: 100%;
  opacity: 0;
  transform: translateY(-100vh);
  transition: all 0s 0.6s cubic-bezier(0.05, 0.8, 0.5, 1);
}
body.cb-toggle-target-active .mobile-navi-animation {
  transition: all 1s cubic-bezier(0.05, 0.8, 0.5, 1);
  transform: translateY(0);
  opacity: 1;
}
.mobile-navigation div.navi {
  float: left;
  width: 100%;
  display: block;
  position: relative;
}
.mobile-navigation div.navi > .cb-toggle {
  display: none;
}
.mobile-navigation div.navi > .item {
  float: left;
  width: 100%;
  position: relative;
  margin: 10px 0;
}
.mobile-navigation div.navi > .item.item-empty > .cb-toggle {
  display: none;
}
.mobile-navigation div.navi > .item > .menu {
  display: block;
  color: #023E84;
  position: relative;
}
.mobile-navigation div.navi > .item > .menu.path {
  color: #F6A124;
}
.mobile-navigation div.sub1 {
  box-sizing: border-box;
  padding: 0 var(--deskSpace);
  margin: 80px 0 30px;
}
.mobile-navigation div.sub1 > .item > .menu {
  font-size: 25px;
  line-height: 1;
  padding-right: 36px;
  padding-bottom: 20px;
}
.mobile-navigation div.sub1 > .item > .menu:after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 9px;
  background-color: #F6A124;
  transition: all 0.4s;
}
.mobile-navigation div.sub1 > .item > .menu.path {
  color: #F6A124;
}
.mobile-navigation div.sub1 > .item > .menu.path:after {
  width: 30px;
}
.mobile-navigation div.sub2 > .item.init {
  margin-top: 20px;
}
.mobile-navigation div.sub2 > .item.exit {
  margin-bottom: 30px;
}
.mobile-navigation div.sub2 {
  overflow: hidden;
  max-height: 0;
}
.mobile-navigation div.sub2 > .item {
  opacity: 0;
  transform: scale(0.8);
}
.mobile-navigation div.navi > .item.cb-toggle-target-active > div.navi {
  transition: none;
  max-height: 1000px;
  transition: max-height 2s;
}
.mobile-navigation div.navi > .item.cb-toggle-target-active > div.navi > .item {
  transform: scale(1);
  opacity: 1;
  transition: all 0.3s;
}
.mobile-navigation .cb-toggle {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
  cursor: pointer;
  width: 36px;
  height: 24px;
  mask-size: 16px 16px;
  mask-position: 50% 50%;
  mask-repeat: no-repeat;
  mask-image: url(/images/cb-default/cb-arrow-down-black.svg);
  background-color: #023E84;
  transition: all 0.3s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.mobile-navigation .cb-toggle.cb-toggle-active {
  transform: rotate(-180deg);
}
#home {
  height: 50px;
}
#head {
  margin-top: var(--spaceTotal);
}
.section--header {
  padding-top: 15px;
  padding-bottom: 15px;
}
@media (max-width: 1023px) {
  .serviceLinks {
    margin-right: 65px;
  }
}
.section--intro {
  margin-bottom: 35px;
}
.container--mood {
  width: calc(100% + 30px);
  margin-top: 30px;
  margin-left: -30px;
}
.container--mood .moodContent {
  margin: 20px;
}
.mood-background {
  width: 100vw;
  height: calc(100% - 100px);
}
.intro-background {
  width: 100vw;
  height: 100%;
}
.designelement--triangles {
  width: 70px;
}
.container--intro {
  padding-left: 15px;
  width: 100%;
}
.container--intro:after {
  width: 100vw;
  height: 100%;
}
.container--facts {
  width: calc(100% + 15px);
  margin-left: -15px;
  padding-left: 15px;
  padding-top: var(--spaceTotal);
  padding-bottom: var(--spaceTotal);
  margin-top: var(--spaceTotal);
  margin-bottom: var(--spaceTotal);
}
.container--facts:after {
  width: 100vw;
  height: 100%;
}
.container--facts .facts {
  width: 100%;
}
.footarea {
  grid-template-columns: 100%;
  width: calc(100% + 30px);
  margin-top: 30px;
  margin-left: -15px;
  padding: 30px 15px;
}
.footpart--one {
  margin-left: 30px;
}
.footpart--two {
  margin-top: var(--spaceTotal);
  margin-left: 30px;
}
.services {
  margin-top: var(--spacePart);
}
.footlogo {
  height: 85px;
}
.area--one {
  grid-template-columns: 100%;
}
.area--one .unitOne .unit__body {
  grid-template-columns: 100%;
}
.area--one .unitTwo {
  width: calc(100% + 30px);
  margin-left: -15px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: calc((var(--spaceTotal) * 3) - var(--spaceUnit));
}
.area--one .unitTwo:first-child {
  margin-top: 0;
}
.area--one .unitTwo:before,
.area--one .unitTwo:after {
  width: 100vw;
  height: 100%;
}
.area--one .unitTwo .less:before {
  width: 50px;
  height: 52px;
}
.area--one .unitTwo .more {
  height: 0;
}
.area--one .unitTwo.unit--foldOpen .more {
  height: calc(var(--js-elementHeight));
}
#edit .area--one .unitTwo.unit--foldOpen .more {
  height: auto;
}
.area--one .unitFour {
  padding-top: 80px;
  padding-bottom: 80px;
}
.area--one .unitFour:before,
.area--one .unitFour:after {
  width: 55px;
  height: 44px;
}
.area--one .unitFive .unit__body {
  grid-template-columns: 100%;
}
.area--one .unitFive .partWidth--1-4 {
  max-width: 125px;
}
.area--intro .unit .unit__body {
  grid-template-columns: 100%;
}
.part--socialLinks {
  width: 100%;
  margin-top: auto;
}
.ns-unitFilter {
  float: left;
  width: 100%;
}
.ns-unitFilter__wrapper {
  float: left;
  width: 100%;
  position: relative;
}
.ns-unitFilter__toggle {
  display: none;
}
.ns-unitFilter__content {
  float: left;
  width: 100%;
}
.ns-unitFilter__list {
  float: left;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 15px 30px;
}
.ns-unitFilter__item {
  float: left;
  font-size: 19px;
  line-height: 1.15789474;
  font-weight: 300;
  padding: 6px 13px;
  box-sizing: border-box;
  border: 1px solid #FFFFFF;
  background-color: #023E84;
  text-align: center;
  transition: all 0.4s;
  text-decoration: none;
  cursor: pointer;
  --s-linkcolor: #FFFFFF;
  --s-linkcolor-hover: #FFFFFF;
}
.ns-unitFilter__item.is-selected,
.ns-unitFilter__item.is-active,
.ns-unitFilter__item:hover,
.ns-unitFilter__item:focus {
  border-color: #F6A124;
}
.ns-unitFilter__unit {
  display: none;
  opacity: 0;
  transition: all 0.4s;
}
.ns-unitFilter__unit.ns-unitFilter__unit--activated {
  display: block;
}
.ns-unitFilter__unit.ns-unitFilter__unit--active {
  opacity: 1;
}
.ns-teaserWrapper {
  float: left;
  width: 100%;
  margin-top: var(--spaceTotal);
  margin-bottom: var(--spaceTotal);
}
.ns-teaserContent {
  float: left;
  width: 100%;
}
.ns-teaserArea {
  float: left;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 30px;
}
@media (max-width: 1023px) {
  .ns-teaserArea {
    grid-template-columns: repeat(6, 1fr);
  }
}
@media (max-width: 767px) {
  .ns-teaserArea {
    grid-template-columns: 100%;
  }
}
.ns-teaser {
  float: left;
  grid-column: span 4;
  position: relative;
}
@media (max-width: 1023px) {
  .ns-teaser {
    grid-column: span 3;
  }
}
@media (max-width: 767px) {
  .ns-teaser {
    grid-column: 1;
  }
}
.ns-teaser__content {
  float: left;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: rgba(246, 161, 36, 0.8);
  aspect-ratio: 0.75;
  box-sizing: border-box;
  padding: 30px;
  opacity: 0;
  transition: all 0.4s;
}
@supports not (aspect-ratio: 3 /  4) {
  .ns-teaser__content:before {
    float: left;
    padding-top: 133.33333333%;
    content: '';
  }
  .ns-teaser__content:after {
    display: block;
    content: '';
    clear: both;
  }
}
.ns-teaser__content:hover,
.ns-teaser__content:focus {
  opacity: 1;
}
.ns-teaser__image {
  float: left;
  width: 100%;
}
.ns-teaser__actions {
  float: left;
  width: 100%;
}
.ns-teaser__button {
  float: right;
  display: inline-block;
  background-color: var(--s-btn-bg);
  font-size: 26px;
  line-height: 1;
  font-weight: bold;
  cursor: pointer;
  color: var(--s-btn-color);
  min-width: 112px;
  padding: 9px 26px 26px 9px;
  appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-decoration: none;
  position: relative;
  transition: all 0.4s;
  --s-btn-bg: #FFFFFF;
  --s-btn-color: #023E84;
  --s-btn-deco-color: #F6A124;
  --s-btn-bg-hover: #023E84;
  --s-btn-color-hover: #F6A124;
  --s-btn-deco-color-hover: #FFFFFF;
}
.ns-teaser__button:before,
.ns-teaser__button:after {
  content: '';
  position: absolute;
  left: 12px;
  bottom: 12px;
  width: 30px;
  height: 9px;
  background-color: var(--s-btn-deco-color);
  transition: all 0.4s;
}
.ns-teaser__button:hover,
.ns-teaser__button:focus {
  background-color: var(--s-btn-bg-hover);
  color: var(--s-btn-color-hover);
}
.ns-teaser__button:hover:after,
.ns-teaser__button:focus:after {
  background-color: var(--s-btn-deco-color-hover);
}
@media (max-width: 1023px) {
  .ns-teaser__button {
    padding: 5px 30px 30px 12px;
    font-size: 21px;
    line-height: 1.19047619;
  }
}
.ns-teaser__part {
  float: left;
  width: 100%;
}
.ns-teaser__part--image {
  aspect-ratio: 0.75;
}
@supports not (aspect-ratio: 3 /  4) {
  .ns-teaser__part--image:before {
    float: left;
    padding-top: 133.33333333%;
    content: '';
  }
  .ns-teaser__part--image:after {
    display: block;
    content: '';
    clear: both;
  }
}
.ns-teaser__part--image * {
  height: 100%;
}
.ns-teaser__part--image img {
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: cover;
  object-position: center;
}
.ns-teaser__part--category {
  padding-bottom: 20px;
  position: relative;
}
.ns-teaser__part--category:after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 30px;
  height: 9px;
  background-color: #FFFFFF;
  transition: all 0.4s;
}
.ns-teaser__part--title {
  hyphens: auto;
}
.ns-teaserLoadMore {
  float: left;
  width: 100%;
  display: flex;
  justify-content: center;
}
.ns-teaserLoadMore__button {
  display: inline-block;
  background-color: var(--s-btn-bg);
  font-size: 26px;
  line-height: 1;
  font-weight: bold;
  cursor: pointer;
  color: var(--s-btn-color);
  min-width: 112px;
  padding: 9px 26px 26px 9px;
  appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-decoration: none;
  position: relative;
  transition: all 0.4s;
  width: 125px;
}
.ns-teaserLoadMore__button:before,
.ns-teaserLoadMore__button:after {
  content: '';
  position: absolute;
  left: 12px;
  bottom: 12px;
  width: 30px;
  height: 9px;
  background-color: var(--s-btn-deco-color);
  transition: all 0.4s;
}
.ns-teaserLoadMore__button:hover,
.ns-teaserLoadMore__button:focus {
  background-color: var(--s-btn-bg-hover);
  color: var(--s-btn-color-hover);
}
.ns-teaserLoadMore__button:hover:after,
.ns-teaserLoadMore__button:focus:after {
  background-color: var(--s-btn-deco-color-hover);
}
@media (max-width: 1023px) {
  .ns-teaserLoadMore__button {
    padding: 5px 30px 30px 12px;
    font-size: 21px;
    line-height: 1.19047619;
  }
}
.ns-teaser__link {
  float: left;
  padding-left: 14px;
  padding-right: 6px;
  background-size: 8px 14px;
  background-position: 0 4px;
  text-decoration: none;
  background-repeat: no-repeat;
  background-image: url(/images/chevron-right-black.svg);
  font-weight: bold;
  text-transform: uppercase;
  transition: all 0.4s;
}
.ns-teaser__link:hover {
  padding-left: 20px;
  padding-right: 0;
  color: #023E84;
  background-image: url(/images/chevron-right-apricot.svg);
}
.cbdModule--blogTeaserFilter {
  margin-top: var(--spaceTotal);
  margin-bottom: var(--spaceTotal);
}
.ns-teaserFilter {
  float: left;
  width: 100%;
}
.ns-teaserFilter__cover {
  display: flex;
  flex-wrap: wrap;
  gap: 15px 30px;
}
.ns-teaserFilter__toggle {
  display: none;
}
.ns-teaserFilter__item {
  float: left;
  font-size: 19px;
  line-height: 1.15789474;
  font-weight: 300;
  padding: 6px 13px;
  box-sizing: border-box;
  border: 1px solid #FFFFFF;
  background-color: #023E84;
  text-align: center;
  transition: all 0.4s;
  text-decoration: none;
  cursor: pointer;
  --s-linkcolor: #FFFFFF;
  --s-linkcolor-hover: #FFFFFF;
}
.ns-teaserFilter__item.is-selected,
.ns-teaserFilter__item.is-active,
.ns-teaserFilter__item:hover,
.ns-teaserFilter__item:focus {
  border-color: #F6A124;
}
.ns-sliderWrapper {
  float: left;
  width: 100%;
}
.ns-teaserSlider {
  float: left;
  width: 100%;
  margin-top: var(--spaceTotal);
  margin-bottom: var(--spaceTotal);
}
.ns-slider--blogTeaser {
  float: left;
  width: 100%;
}
.ns-slider--blogTeaser .ns-slider__content {
  float: left;
  width: 100%;
  position: relative;
}
@media (max-width: 1023px) {
  .ns-slider--blogTeaser .ns-slider__content {
    width: calc(100% - 40px);
    margin: 0 20px;
  }
}
.ns-slider--blogTeaser .ns-slider__stage {
  float: left;
  width: 100%;
  overflow: hidden;
}
.ns-slider--blogTeaser .ns-slider__area {
  float: left;
  width: calc(100% + 30px);
  margin-left: -15px;
  display: flex;
}
.ns-slider--blogTeaser .ns-slide {
  float: left;
  width: calc((100% / 3) - 30px);
  margin: 0 15px;
  flex-shrink: 0;
  position: relative;
  transition: all 0.6s;
}
@media (max-width: 1023px) {
  .ns-slider--blogTeaser .ns-slide {
    width: calc(50% - 30px);
  }
}
@media (max-width: 767px) {
  .ns-slider--blogTeaser .ns-slide {
    width: calc(100% - 30px);
  }
}
.ns-slider--blogTeaser .ns-slide__container {
  float: left;
  width: 100%;
}
.ns-slider--blogTeaser .ns-slide__body {
  float: left;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: rgba(246, 161, 36, 0.8);
  aspect-ratio: 0.75;
  box-sizing: border-box;
  padding: 30px;
  opacity: 0;
  transition: all 0.4s;
}
@supports not (aspect-ratio: 3 /  4) {
  .ns-slider--blogTeaser .ns-slide__body:before {
    float: left;
    padding-top: 133.33333333%;
    content: '';
  }
  .ns-slider--blogTeaser .ns-slide__body:after {
    display: block;
    content: '';
    clear: both;
  }
}
.ns-slider--blogTeaser .ns-slide__body:hover,
.ns-slider--blogTeaser .ns-slide__body:focus {
  opacity: 1;
}
.ns-slider--blogTeaser .ns-sliderControl {
  width: 25px;
  height: 31px;
  mask-size: 100% 100%;
  mask-position: 50% 50%;
  mask-repeat: no-repeat;
  background-color: #023E84;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.4s;
}
.ns-slider--blogTeaser .ns-sliderControl:hover,
.ns-slider--blogTeaser .ns-sliderControl:focus {
  background-color: #F6A124;
}
.ns-slider--blogTeaser .ns-sliderControl.is-disabled {
  display: none;
}
.ns-slider--blogTeaser .ns-sliderControl--prev {
  left: -70px;
  mask-image: url(/images/arrow-left.svg);
}
@media (max-width: 1023px) {
  .ns-slider--blogTeaser .ns-sliderControl--prev {
    left: -40px;
  }
}
.ns-slider--blogTeaser .ns-sliderControl--next {
  right: -70px;
  mask-image: url(/images/arrow-right.svg);
}
@media (max-width: 1023px) {
  .ns-slider--blogTeaser .ns-sliderControl--next {
    right: -40px;
  }
}
.cbdModule--toBlogButton .open {
  float: right;
  display: inline-block;
  background-color: var(--s-btn-bg);
  font-size: 26px;
  line-height: 1;
  font-weight: bold;
  cursor: pointer;
  color: var(--s-btn-color);
  min-width: 112px;
  padding: 9px 26px 26px 9px;
  appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-decoration: none;
  position: relative;
  transition: all 0.4s;
}
.cbdModule--toBlogButton .open:before,
.cbdModule--toBlogButton .open:after {
  content: '';
  position: absolute;
  left: 12px;
  bottom: 12px;
  width: 30px;
  height: 9px;
  background-color: var(--s-btn-deco-color);
  transition: all 0.4s;
}
.cbdModule--toBlogButton .open:hover,
.cbdModule--toBlogButton .open:focus {
  background-color: var(--s-btn-bg-hover);
  color: var(--s-btn-color-hover);
}
.cbdModule--toBlogButton .open:hover:after,
.cbdModule--toBlogButton .open:focus:after {
  background-color: var(--s-btn-deco-color-hover);
}
@media (max-width: 1023px) {
  .cbdModule--toBlogButton .open {
    padding: 5px 30px 30px 12px;
    font-size: 21px;
    line-height: 1.19047619;
  }
}
@media (max-width: 1023px) {
  .cbdModule--toBlogButton .open {
    margin-right: 20px;
  }
}
.ns-sliderWrapper {
  float: left;
  width: 100%;
}
.ns-slider--customerSlider {
  float: left;
  width: 100%;
}
.ns-slider--customerSlider .ns-slider__section {
  float: left;
  width: 100%;
  position: relative;
}
@media (max-width: 1023px) {
  .ns-slider--customerSlider .ns-slider__section--content {
    width: calc(100% - 40px);
    margin: 0 20px;
  }
}
.ns-slider--customerSlider .ns-slider__stage {
  float: left;
  width: 100%;
  overflow: hidden;
}
.ns-slider--customerSlider .ns-slider__area {
  float: left;
  display: flex;
  width: calc(100% + 30px);
  margin-left: -15px;
}
.ns-slider--customerSlider .ns-slide {
  float: left;
  width: calc(100% - 30px);
  margin: 0 15px;
  padding: 0 55px;
  box-sizing: border-box;
  text-align: center;
  flex-shrink: 0;
  transition: all 0.4s;
}
@media (max-width: 1023px) {
  .ns-slider--customerSlider .ns-slide {
    padding: 0;
  }
}
.ns-slider--customerSlider .ns-slide__container {
  float: left;
  width: 100%;
}
.ns-slider--customerSlider .ns-slide__body {
  float: left;
  width: 100%;
}
.ns-slider--customerSlider .ns-slider__part {
  float: left;
  width: 100%;
  margin: var(--spacePart) 0;
}
.ns-slider--customerSlider .ns-sliderControl {
  width: 25px;
  height: 31px;
  mask-size: 100% 100%;
  mask-position: 50% 50%;
  mask-repeat: no-repeat;
  background-color: #023E84;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.4s;
}
.ns-slider--customerSlider .ns-sliderControl:hover,
.ns-slider--customerSlider .ns-sliderControl:focus {
  background-color: #F6A124;
}
.ns-slider--customerSlider .ns-sliderControl.is-disabled {
  display: none;
}
.ns-slider--customerSlider .ns-sliderControl--prev {
  left: -70px;
  mask-image: url(/images/arrow-left.svg);
}
@media (max-width: 1023px) {
  .ns-slider--customerSlider .ns-sliderControl--prev {
    left: -40px;
  }
}
.ns-slider--customerSlider .ns-sliderControl--next {
  right: -70px;
  mask-image: url(/images/arrow-right.svg);
}
@media (max-width: 1023px) {
  .ns-slider--customerSlider .ns-sliderControl--next {
    right: -40px;
  }
}
/*# sourceMappingURL=./screen-small.css.map */